import { request } from "@/Util/Request";

export default {
    data() {
        return {
            dropdowns: {
                addresses: {
                    arrival_address: [],
                    residential_address: [],
                    origin_address: []
                }
            },
        };
    },
    methods: {
        onFocus(key) {
            if ( _.isEmpty(this.dropdowns.addresses[key]) ) {
                this.dropdowns.addresses[key] = [
                    {
                        id: "default",
                        label: `Type to search...`,
                    }
                ];
            }
        },
        async search(key) {
            if ( key ) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `dropdowns/places`,
                        data: {
                            text: this.formFields[key]
                        }
                    });

                    const { results } = response;

                    this.dropdowns.addresses[key] = _.map(results, (i) => {
                        return {
                            id: i.formatted,
                            label: i.formatted
                        };
                    });

                } catch (error) {
                    return [];
                }
                return [];
            }
        },
        selectSuggestion(suggestion, key) {
            this.formFields[key] = suggestion.label;
            this.dropdowns.addresses[key] = [];
        },
    }
};
