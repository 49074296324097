<template>
    <div class="register w-100 h-100 d-flex">
        <section class="auth col-12 mx-auto align-self-md-center col-md-7 col-xl-5 col-xxl-4">
            <div class="half-design row">
                <div class="col-12 form-block">
                    <div class="logo col-auto p-0">
                        <img src="@/assets/logo_full.svg" alt="logo" height="70px" class="mx-auto" />
                    </div>
                    <p class="text-center mt-3">
                        {{ $t('title.registerToHelp') }}<br />
                        {{ $t('title.joinUs') }}
                    </p>

                    <div class="col-12 col-md-9 mx-auto">
                        <div class="mb-3" v-if="!phoneVerified">
                            <label for="Phone">{{ $t('title.enterPhoneNumber') }}</label
                            >
                            <vue-tel-input
                                :class="formErrors.has('phone') ? `error-input` : ``"
                                v-model="formFields.phone"
                                v-bind="phone.options"
                                @on-input="onInput"
                                required
                            />
                            <b-form-invalid-feedback
                                :state="!formErrors.has('phone')">
                                {{ formErrors.first('phone') }}
                            </b-form-invalid-feedback>
                            <b-button type="button" @click="onSubmitRegistration" variant="primary" class="mt-3 w-100">
                                {{ $t('title.getCode') }}
                            </b-button>
                        </div>

                        <div v-else-if="!otpVerified" class="mb-3">
                            <label>{{ $t('title.enterVerificationCode') }}</label>
                            <div class="d-flex gap-3 text-center">
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code1"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code1', e)"
                                    @keydown.native="e => handleKeyDown('code1', e)"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code2"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code2', e)"
                                    @keydown.native="e => handleKeyDown('code2', e)"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code3"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code3', e)"
                                    @keydown.native="e => handleKeyDown('code3', e)"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code4"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code4', e)"
                                    @keydown.native="e => handleKeyDown('code4', e)"
                                    maxlength="1"
                                />
                            </div>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('otp')">
                                {{ formErrors.first('otp') }}
                            </b-form-invalid-feedback>
                            <b-button type="button" @click.prevent="onSubmit" variant="primary" class="mt-3 w-100">
                                Verify Code
                            </b-button>
                        </div>

                        <div v-else>
                            <b-form-group :label="$t('title.registerAs')" label-for="role_id">
                                <treeselect @input="resetError" v-model="formFields.role_id" class="form-input"
                                            :class="formErrors.has('role_id') ? `error-input` : ``"
                                            :options="dropdowns.roles"
                                            required />
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('role_id')">
                                    {{ formErrors.first('role_id') }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('title.disability')" label-for="disability">
                                <treeselect @input="resetError" v-model="formFields.disability_id" class="form-input"
                                            :class="formErrors.has('disability_id') ? `error-input` : ``"
                                            :options="dropdowns.disabilities" required />
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('disability_id')">
                                    {{ formErrors.first('disability_id') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.fullName')" label-for="full-name">
                                <b-form-input v-model="formFields.full_name" id="full-name"
                                              :class="formErrors.has('full_name') ? `error-input` : ``"
                                              required></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('full_name')">
                                    {{ formErrors.first('full_name') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.email')" label-for="email">
                                <b-form-input type="email" v-model="formFields.email" id="email"
                                              :class="formErrors.has('email') ? `error-input` : ``"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('email')">
                                    {{ formErrors.first('email') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.residentialAddress')" label-for="address">
                                <b-form-input v-model="formFields.residential_address" id="address"
                                              :class="formErrors.has('residential_address') ? `error-input` : ``"
                                              required></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('residential_address')">
                                    {{ formErrors.first('residential_address') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-button type="button" @click="onFinalSubmit" variant="primary" class="mb-3 w-100">
                                {{ $t('title.register') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { getAuthUser, setStorage } from "@/Util/auth";
import { mapGetters } from "vuex";
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import commonMixin from "@/Util/commonMixin";
import { reactive } from "vue";

const FORM_STATE = {
    phone: null,
    disability_id: null,
    full_name: null,
    email: null,
    role_id: null,
    residential_address: null,
    otp: {
        code1: null,
        code2: null,
        code3: null,
        code4: null,
    },
};

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

export default {
    mixins: [commonMixin],
    data() {
        return {
            formFields: { ...FORM_STATE },
            isValidP: false,
            phoneVerified: false,
            otpVerified: null,
            inputOptions: {
                placeholder: 'Enter your phone number',
                autofocus: true,
            },
            dropdowns: {
                disabilities: [],
                roles: [],
            },
            formErrors: new Error({}),
            number: null
        };
    },
    computed: {
        ...mapGetters(['user', 'loader'])
    },
    mounted() {
        if ( !getAuthUser() ) {
            this.getDropdowns();
        }
    },
    methods: {
        onInput(formattedNumber, input) {
            this.phone.inputValue = input;
            setTimeout(() => {
                this.formFields.phone = input.number;
            }, 0);
        },
        resetError() {
            this.formErrors = new Error({});
        },
        async onSubmitRegistration() {
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {
                try {
                    const response = await request({
                        method: 'post',
                        url: `/auth/register-otp`,
                        data: {
                            phone: this.formFields.phone
                        }
                    });

                    this.phoneVerified = true;

                    this.notifySuccessWithMsg(this.$t('title.otpSentSuccessMsg'));

                } catch (error) {
                    this.notifyError();

                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    }
                }
            }
        },
        async onSubmit() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/auth/verify-register-otp`,
                    data: {
                        phone: this.formFields.phone,
                        otp: _.map(this.formFields.otp).join('')
                    }
                });

                this.otpVerified = true;

                this.notifySuccessWithMsg(this.$t('title.otpVerifiedMsg'));

            } catch (error) {

                this.notifyError();

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        async onFinalSubmit() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/auth/register`,
                    data: {
                        ...this.formFields
                    }
                });

                const { data } = response;

                await this.redirectingUserPage(data);

                this.notifySuccessWithMsg(`Your account register successfully`);

            } catch (error) {
                this.notifyError();

                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        handleInput(codeField, event) {
            const value = event.target.value;
            if ( !/^\d$/.test(value) ) {
                this.formFields.otp[codeField] = '';
                return;
            }
            this.formFields.otp[codeField] = value;

            const nextInput = event.target.nextElementSibling;
            if ( nextInput && nextInput.tagName === 'INPUT' ) {
                nextInput.focus();
            }
        },
        handleKeyDown(codeField, event) {
            if ( event.key === 'Backspace' && !this.formFields.otp[codeField] ) {
                const previousInput = event.target.previousElementSibling;
                if ( previousInput && previousInput.tagName === 'INPUT' ) {
                    previousInput.focus();
                }
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/register`
                });

                const { roles, disabilities } = response;
                this.dropdowns.roles = roles;
                this.dropdowns.disabilities = disabilities;

            } catch (error) {
            }
        },
    },
};
</script>
