<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDeleteModal"  :hide-header="true"
                 :hide-footer="true">
            <div v-if="statusCounts.length > 0">
                <p><b>Following table is display user existing applications status</b></p>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Application Status</th>
                        <th>Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="status in statusCounts" :key="status.status">
                        <td>{{ status.status }}</td>
                        <td>{{ status.count }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <p>Are you sure you want to delete user?</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

export default {
    data() {
        return {
            isOpenModal: false,
            statusCounts : [],
            userId: null,
            formErrors: new Error({})
        };
    },
    methods: {
        async handleToggleModal(id) {
            this.userId = id;
            try {
                const response = await request({
                    method: 'post',
                    url: `admin/users/applications/${ id }`,

                });

                let { data } = response;
                this.statusCounts = data;
            } catch (error) {
                this.notifyError();
            }

            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitRequest() {
            try {
                const response = await request({
                    method: 'delete',
                    url: `/admin/users/${ this.userId }`,

                });
                this.notifySuccessWithMsg(`User ${ this.$t('title.deletedSuccessMsg') }`);
                this.$emit('refreshTable');
                this.cancelModal();
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
