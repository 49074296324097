import { render, staticRenderFns } from "./VolunteerDeleteConfirmationModal.vue?vue&type=template&id=7dd7529a"
import script from "./VolunteerDeleteConfirmationModal.vue?vue&type=script&lang=js"
export * from "./VolunteerDeleteConfirmationModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports