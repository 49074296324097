<template>
    <header class="row mx-0 align-items-center main-header">
        <div class="logo col-auto p-0">
            <img src="../assets/logo_full.svg" alt="logo" height="50px" class="d-none d-md-flex" />
            <img src="../assets/logo_small.svg" alt="logo" height="50px" class="d-md-none" />
        </div>
        <div class="menu col-auto ms-auto position-relative p-0 gap-3 d-flex">
            <b-button
                @click="$emit('toggle-sidebar')"
                variant="link"
                class="ms-auto p-0 d-lg-none toggleSidebar"
                id="toggleSidebar"
            >
                <b-icon icon="list"></b-icon>
            </b-button>

            <b-dropdown right>
                <template #button-content>
                    <div class="box image rounded-circle lang">
                        <b-icon icon="translate"></b-icon>
                    </div>
                </template>
                <b-dropdown-item
                    class="notify-item"
                    v-for="(entry, i) in languages"
                    :key="`Lang${i}`"
                    :value="entry"
                    @click="setLanguage(entry.language, entry.title)"
                >
                    <span class="align-middle">{{ entry.title }}</span>
                </b-dropdown-item>
            </b-dropdown>

            <!-- User Dropdown -->
            <b-dropdown right v-if="user">
                <template #button-content>
                    <div class="box image rounded-circle">
                        <b-icon icon="person"></b-icon>
                    </div>
                </template>
                <router-link to="/basket-menu" class="dropdown-item" v-if="$global.hasRole('volunteer')">
                    <b-icon icon="basket"></b-icon>
                    {{ $t('title.myVolunteerBasket') }}
                </router-link>
                <router-link :to="{name:'Profile'}" class="dropdown-item" v-if="!$global.hasRole('admin')">
                    <b-icon icon="gear"></b-icon>
                    {{ $t('title.editingMyProfile') }}
                </router-link>
                <router-link :to="{name:'AdminProfile'}" class="dropdown-item" v-else>
                    <b-icon icon="gear"></b-icon>
                    {{ $t('title.editingMyProfile') }}
                </router-link>
                <b-dropdown-divider></b-dropdown-divider>
                <a href="javascript:void(0);" class="dropdown-item" @click="handleSubmitLogout">
                    <b-icon icon="box-arrow-right"></b-icon>
                    {{ $t('title.exitFromSystem') }}
                </a>
            </b-dropdown>
        </div>
    </header>
</template>

<script>
import { hasRole, refresh, removeStorage, setStorage } from "@/Util/auth";
import { request } from "@/Util/Request";
import { mapGetters } from "vuex";

export default {
    name: 'AppHeader',
    computed: {
        ...mapGetters(['user']),
    },
    data() {
        return {
            languages: [
                {
                    language: "en-US",
                    title: "English",
                },
                {
                    language: "he-IL",
                    title: "Hebrew",
                },
            ],
        };
    },
    methods: {
        setLanguage(locale, country) {
            this.text = country;
            this.$store.commit('CHANGE_SETTING', locale);
            setStorage(`locale`, locale);
            refresh();
        },
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        },
        async handleSubmitLogout() {
            const adminRole = hasRole('admin');

            if ( confirm(`${ this.$t('title.areYouSureWantToLogout') }`) ) {
                try {
                    const response = await request({
                        method: 'post',
                        url: adminRole ? `/admin/auth/logout` : `/auth/logout`,
                    });

                    removeStorage('auth');

                    await this.$store.dispatch('user', null);
                    await this.$store.dispatch('setUnAuthorized', true);

                    if ( !adminRole ) {
                        await this.$router.push({ name: 'Login' });
                    } else {
                        await this.$router.push({ name: 'AdminLogin' });
                    }

                } catch (error) {
                    this.notifyError();
                }
            }
        },
    },
};
</script>
