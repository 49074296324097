<template>
    <div class="main-block user-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.userManagement') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <crud-table :columns="columns" :list-url="listUrl" ref="users-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.type')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.roles"
                                            class="form-input"
                                            :options="dropdowns.roles" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template #cell(is_wp)="{item}">
                        {{ ( item.is_phone ) ? 'Phone' : '' }}{{ ( item.is_phone && item.is_wp ) ? ',' : '' }}{{ ( item.is_wp ) ? 'WhatsApp' : '' }}
                    </template>
                    <template #cell(disability)="{item}">
                        {{ ( item.disability || {} ).name }}
                    </template>
                    <template #cell(email_verified_at)="{item}">
                        {{ $global.dateFormat(item.email_verified_at) }}
                    </template>
                    <template #cell(status)="{item}">
                        <span :class="(item.status == 'Active') ? 'text-success' : 'text-danger'"> {{ item.status }} </span>
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button
                            v-if="_.filter(item.roles,(i) => i.name == 'user').length && !item.has_admin_role"
                            title="become an admin"
                            variant="outline-primary"
                            class="mr-1" @click="handleBecomeAdmin(item.id)">
                            <b-icon icon="person" class="icon-style"></b-icon>
                        </b-button>
                        <b-button
                            v-if="user?.id !== item.id && !item.deleted_at"
                            variant="outline-primary"
                            class="mr-1" @click="handleDelete(item.id, item.roles)">
                            <b-icon icon="trash" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>
        <UserDeleteConfirmationModal ref="userDeleteModal" @refreshTable="handleRefreshTable"/>
        <VolunteerDeleteConfirmationModal ref="volunteerDeleteModal" @refreshTable="handleRefreshTable"/>
    </div>
</template>

<script>

import { request } from "@/Util/Request";
import { mapState } from "vuex";
import UserDeleteConfirmationModal from "@/view/pages/admin/modal/UserDeleteConfirmationModal";
import VolunteerDeleteConfirmationModal from "@/view/pages/admin/modal/VolunteerDeleteConfirmationModal";

const FILTER_FORM = {
    roles: null
};

const COLUMN_STATE = (self) => [
    { key: 'full_name', label: self.$t('title.fullName'), sortable: true },
    { key: 'is_wp', label: self.$t('title.type'), sortable: true },
    { key: 'phone', label: self.$t('title.phone'), sortable: true },
    { key: 'email', label: self.$t('title.email'), sortable: true },
    { key: 'email_verified_at', label: self.$t('title.registrationDate'), sortable: true },
    { key: 'disability', label: self.$t('title.disability'), sortable: true },
    { key: 'residential_address', label: self.$t('title.residentialAddress'), sortable: true },
    { key: 'current_address', label: self.$t('title.currentAddress'), sortable: true },
    {
        key: 'is_notification_alert',
        label: self.$t('title.alerts'),
        formatter: (value, key, item) => {
            return value ? 'Yes' : 'No';
        },
        sortable: true,
        sortByFormatted: true,
        filterByFormatted: true,
    },
    { key: 'status', label: self.$t('title.status')},
    { key: 'actions', label: self.$t('title.actions') },
];

const DEFAULT_DROPDOWNS = (self) => ( {
    roles: [
        { id: 'all', label: self.$t('title.all') },
        { id: 'volunteer', label: self.$t('title.volunteer') },
        { id: 'user', label: self.$t('title.user') },
    ]
} );


export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/users',
            filters: {
                ...FILTER_FORM
            }
        };
    },
    components : {
        UserDeleteConfirmationModal,
        VolunteerDeleteConfirmationModal
    },
    methods: {
        handleRefreshTable() {
            this.$refs['users-table'].handleRefreshList();
        },
        handleFilter() {
            this.$refs['users-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
        async handleDelete(id, roles) {
            let role = roles[0].name;
            if(role == 'user') {
                this.$refs['userDeleteModal'].handleToggleModal(id);
            } else {
                this.$refs['volunteerDeleteModal'].handleToggleModal(id);
            }
        },
        async handleBecomeAdmin(id) {
            if ( confirm('Are you want to assign admin role to this user?') ) {
                {
                    try {
                        const response = await request({
                            method: 'post',
                            url: `admin/users/become/admin/${ id }`,

                        });

                        this.notifySuccessWithMsg(`Admin role assigned to user successfully`);
                        this.$refs['users-table'].handleRefreshList();

                    } catch (error) {
                        this.notifyError();
                    }
                }
            }

        },
    },
    computed: {
        dropdowns() {
            return DEFAULT_DROPDOWNS(this);
        },
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['user'])
    }
};
</script>
