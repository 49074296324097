<template>
    <div>
        <b-modal v-model="isOpenModal" id="volunteerDeleteModal"  :hide-header="true"
                 :hide-footer="true">
            <div class="mb-3">
                <p><b>This is an open volunteer list. You can select this volunteer to assign all existing requests.</b></p>
                <label for="type">Select Volunteer</label>
                <treeselect :multiple="false" v-model="formFields.volunteer_id" :options="volunteers"
                            id="type"></treeselect>
                <b-form-invalid-feedback
                    :state="!formErrors.has('volunteer_id')">
                    {{ formErrors.first('volunteer_id') }}
                </b-form-invalid-feedback>
            </div>

            <p>Are you sure you want to delete volunteer ?</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" :disabled="(formFields.volunteer_id) ? false : true" class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    volunteer_id: null
};

export default {
    data() {
        return {
            isOpenModal: false,
            statusCounts : [],
            userId: null,
            volunteers: [],
            formFields: { ...FORM_STATE },
            formErrors: new Error({})
        };
    },
    methods: {
        async handleToggleModal(id) {
            try {
                this.userId = id;
                const response = await request({
                    method: 'post',
                    url: `admin/volunteer/lists/${ id }`,

                });

                let { data } = response;
                this.volunteers = data;

            } catch (error) {
                this.notifyError();
            }

            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitRequest() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/admin/volunteer/delete/${ this.userId }`,
                    data: this.formFields
                });
                this.notifySuccessWithMsg(`User ${ this.$t('title.deletedSuccessMsg') }`);
                this.$emit('refreshTable');
                this.cancelModal();
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
